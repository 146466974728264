<template>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container">
            <!--begin::Row-->
            <div class="row">
                <!--begin::Col-->

                <template v-for="(item, i) in listUsers">
                    <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6" v-bind:key="i">
                        <UsersItem :user="item" />
                    </div>
                </template>
                <!--end::Col-->
            </div>
            <!--end::Row-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Entry-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapActions } from "vuex";
import UsersItem from "./UsersItem";

export default {
    name: "Users",
    components: {
        UsersItem,
    },
    data() {
        return {
            prevRoute: null,
        };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Lista utilizatori" }]);
    },
    computed: {
        ...mapGetters(["user"]),
        ...mapGetters("departments", ["departments"]),
        ...mapActions("departments", ["getDepartments"]),
        ...mapActions("users", ["getUsers"]),
        listUsers() {
            var myUsers = [];
            if (this.user.permissions.can_manage_all_users) {
                myUsers = this.$store.getters["users/users"];
            } else if (this.user.permissions.can_manage_users) {
                this.departments.forEach((department) => {
                    if (department.isManager) {
                        department.users.forEach((curUser) => {
                            var isInArray = myUsers.filter(function (elem) {
                                if (elem.Name == "Power") return elem.Value;
                            });
                            if (isInArray.length == 0) {
                                myUsers.push(curUser);
                            }
                        });
                    }
                });
            }
            return myUsers;
            // return this.$store.getters["users/users"];
        },
    },
    methods: {},
    created() {
        this.getDepartments;
        this.getUsers;
    },
};
</script>
