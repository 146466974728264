<template>
    <!--begin::Card-->
    <div class="card card-custom gutter-b card-stretch">
        <!--begin::Body-->
        <router-link :to="{ name: 'EditUserInfo', params: { userId: user.id } }">
            <div class="card-body pt-4">
                <!--begin::User-->
                <div class="d-flex align-items-center mb-7">
                    <!--begin::Pic-->
                    <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                        <div
                            class="symbol symbol-lg-75 symbol-photo"
                            :class="[
                                user.status === 'active'
                                    ? 'symbol-light-success active'
                                    : '',
                                user.status === 'vacation'
                                    ? 'symbol-light-danger vacation'
                                    : '',
                                user.status === 'busy' ? 'symbol-light-warning busy' : '',
                            ]"
                        >
                            <span
                                data-v-75a12f96=""
                                class="symbol-label font-size-h5 font-weight-bold symbol-photo"
                                v-if="
                                    user.photoUrl !=
                                    'https://sharedmailapi.pefoc.ro/img/default-avatar.png'
                                "
                            >
                                <img :src="user.photoUrl" alt="image" />
                            </span>
                            <span
                                class="symbol-label font-size-h3 font-weight-boldest"
                                v-else
                                >{{
                                    user.firstName.charAt(0) + user.lastName.charAt(0)
                                }}</span
                            >
                        </div>
                    </div>
                    <!--end::Pic-->
                    <!--begin::Title-->
                    <div class="d-flex flex-column">
                        <a
                            href="#"
                            class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0"
                            >{{ user.firstName }} {{ user.lastName }}</a
                        >
                        <span class="text-muted font-weight-bold">{{
                            user.jobTitle ? user.jobTitle : "-"
                        }}</span>
                    </div>
                    <!--end::Title-->
                </div>
                <!--end::User-->
                <!--begin::Info-->
                <div class="">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="text-dark-75 font-weight-bolder mr-2"
                            >Nickname:</span
                        >
                        <a href="#" class="text-muted text-hover-primary">{{
                            user.nickName ? user.nickName : " - "
                        }}</a>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="text-dark-75 font-weight-bolder mr-2">Email:</span>
                        <a href="#" class="text-muted text-hover-primary">{{
                            user.email
                        }}</a>
                    </div>
                    <div class="d-flex justify-content-between align-items-cente my-2">
                        <span class="text-dark-75 font-weight-bolder mr-2">Phone:</span>
                        <a href="#" class="text-muted text-hover-primary">{{
                            user.phone ? user.phone : " - "
                        }}</a>
                    </div>
                </div>
                <!--end::Info-->

                <!-- 
                <span class="btn btn-sm btn-light-primary font-weight-bolder">edit</span> -->
            </div>
        </router-link>
        <!--end::Body-->
    </div>
    <!--end::Card-->
</template>

<script>
// import { mapGetters } from "vuex";

export default {
    name: "UsersItem",
    props: ["user"],
    components: {},
    data() {
        return {};
    },
    mounted() {},
    computed: {},
    methods: {},

    created() {},
};
</script>
<style scoped>
.symbol-label img {
    width: 90%;
    border-radius: 0.42rem;
}

div .symbol.active .symbol-label.symbol-photo {
    background-color: #1bc5bd;
}
div .symbol.busy .symbol-label.symbol-photo {
    background-color: #f3c200;
}
div .symbol.vacation .symbol-label.symbol-photo {
    background-color: #f64e60;
}
</style>
