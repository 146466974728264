var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom gutter-b card-stretch"},[_c('router-link',{attrs:{"to":{ name: 'EditUserInfo', params: { userId: _vm.user.id } }}},[_c('div',{staticClass:"card-body pt-4"},[_c('div',{staticClass:"d-flex align-items-center mb-7"},[_c('div',{staticClass:"flex-shrink-0 mr-4 mt-lg-0 mt-3"},[_c('div',{staticClass:"symbol symbol-lg-75 symbol-photo",class:[
                            _vm.user.status === 'active'
                                ? 'symbol-light-success active'
                                : '',
                            _vm.user.status === 'vacation'
                                ? 'symbol-light-danger vacation'
                                : '',
                            _vm.user.status === 'busy' ? 'symbol-light-warning busy' : '',
                        ]},[(
                                _vm.user.photoUrl !=
                                'https://sharedmailapi.pefoc.ro/img/default-avatar.png'
                            )?_c('span',{staticClass:"symbol-label font-size-h5 font-weight-bold symbol-photo",attrs:{"data-v-75a12f96":""}},[_c('img',{attrs:{"src":_vm.user.photoUrl,"alt":"image"}})]):_c('span',{staticClass:"symbol-label font-size-h3 font-weight-boldest"},[_vm._v(_vm._s(_vm.user.firstName.charAt(0) + _vm.user.lastName.charAt(0)))])])]),_c('div',{staticClass:"d-flex flex-column"},[_c('a',{staticClass:"text-dark font-weight-bold text-hover-primary font-size-h4 mb-0",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName))]),_c('span',{staticClass:"text-muted font-weight-bold"},[_vm._v(_vm._s(_vm.user.jobTitle ? _vm.user.jobTitle : "-"))])])]),_c('div',{},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"text-dark-75 font-weight-bolder mr-2"},[_vm._v("Nickname:")]),_c('a',{staticClass:"text-muted text-hover-primary",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.user.nickName ? _vm.user.nickName : " - "))])]),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"text-dark-75 font-weight-bolder mr-2"},[_vm._v("Email:")]),_c('a',{staticClass:"text-muted text-hover-primary",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.user.email))])]),_c('div',{staticClass:"d-flex justify-content-between align-items-cente my-2"},[_c('span',{staticClass:"text-dark-75 font-weight-bolder mr-2"},[_vm._v("Phone:")]),_c('a',{staticClass:"text-muted text-hover-primary",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.user.phone ? _vm.user.phone : " - "))])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }